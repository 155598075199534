<div class="modal">
  <div class="container model-container full {{context.customClass}}">
    <div class="modal-header">
      <button class="modal-close modal-toggle" (click)="closeDialog()"><i class="icon-close"></i></button>
      <h2 class="modal-heading">Select Your Preferred Method of Payment</h2>
    </div>
    <div class="modal-body">
      <div class="modal-content" [class.more-bottom]="nonExpiredmethodsList?.length <= 0 || methodsList?.length <= 0">
        <ng-container *ngIf="nonExpiredmethodsList?.length > 0">
          <p class="note">Choose a different payment method below:</p>
          <div class="account-address-details-container highlight-card" *ngFor="let methodItem of nonExpiredmethodsList"
            (click)="cardSelected(methodItem.id)" [class.red-border]="!!displayRequiredMsg">
            <div class="custom-checkbox">
              <div class="checkbox">
                <input title="{{methodItem.alias}}" type="radio" [checked]="methodItem.id === selectedMethodId"
                  name="shippingAddress" [id]="methodItem.id" [value]="methodItem">
                <label [for]="methodItem.id"></label>
              </div>
            </div>
            <div class="details">
              <p class="smaller"><b>{{checkBoxLabel(methodItem)}}</b></p>
              <p class="smaller">Ending in {{methodItem.last4}},</p>
              <p class="smaller">Expiry date: {{methodItem.expirationDate | expirationDateFormat}}</p>
            </div>
          </div>
        </ng-container>
        <div *ngIf="nonExpiredmethodsList?.length <= 0 && expiredMethodsList?.length === 1" id="expired-card">
          <p id="expired-message">Your saved credit card has expired.</p>
          <p id="expired-links">Kindly <a (click)="goToSettings()">update your payment method</a>, or <a
              (click)="goToSettings()">add a new one</a>.</p>
        </div>
        <div *ngIf="nonExpiredmethodsList?.length <= 0 && expiredMethodsList?.length > 1" id="expired-cards">
          <p id="expired-message">Your saved credit cards have expired.</p>
          <p id="expired-links">Kindly <a (click)="goToSettings()">update your payment methods</a>, or <a
              (click)="goToSettings()">add a new one</a>.</p>
        </div>
        <div *ngIf="methodsList?.length <= 0" id="no-payments">
          <p>You do not have saved credit cards in your profile</p>
          <a (click)="goToSettings()">you can add one from profile settings.</a>
        </div>
        <div class="row form-section modal-actions" *ngIf="nonExpiredmethodsList?.length > 0">
          <p class="modal-hint">You can also add another payment method in <a (click)="goToSettings()">Profile
              Settings</a></p>
          <p class="validation-message modal-hint" *ngIf="!!displayRequiredMsg"><b>Select a payment method to proceed </b></p>

          <button class="button primary" data-cy="save" (click)="makePaymentMethodAsDefault(selectedMethodId)"
            [class.disabled]="selectedMethodId === context.userPlan.paymentMethodId || (setDefaultRequest)"
            [disabled]="selectedMethodId === context.userPlan.paymentMethodId || (setDefaultRequest)"
            [uiBlockButton]="setDefaultRequest">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</div>